<template>
  <v-app>
    <template v-if="isNotApp">
      <v-main>
        <router-view/>
      </v-main>
    </template>
    <template v-else>
        <AppBar :isMini="isMini" @isMini="isMini = $event" ref="AppBarComponent"/>

        <Nav :user_data="getUserData" :isMini="isMini" @ticket="createTicket=true;"/>

        <Tickets @close-success="success=true; createTicket=false;" @close="createTicket=false" :openDialog="createTicket" />

        <SuccessSnackbar message="Ticket criado com sucesso!" v-model="success"/>

        <Main @user-data="userDataMethod" />

        <Footer/>
    </template>



    <v-dialog  v-model="updateExists" width="25%">
      <v-card>
        <v-card-title class="primary white--text">
          Atualização!
        </v-card-title>
        <v-card-text class="mt-4">
          Existe uma atualização disponível e, para ter acesso a todas as funcionalidades (inclusive correção de erros) deverá atualizar a aplicação!
        </v-card-text>
        <v-card-actions>
          <v-btn class="success" text @click="refreshApp" style="display: block; margin: auto;">
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import AppBar from "@/components/UI/Template/AppBar";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

import Tickets from "@/views/Tickets/Tickets.vue"

import Nav from "@/components/UI/Template/Nav";

import Main from "@/components/UI/Template/Main";

import Footer from "@/components/UI/Template/Footer";

import update from './mixins/update'

export default {
  name: 'App',
  mixins: [update],
  components: {
    AppBar,
    Nav,
    Main,
    Footer,
    Tickets,
    SuccessSnackbar
  },
  data: () => ({
    mini: null,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    createTicket: false,
    success:false,
    userData: null
  }),
  mounted(){
    if(!this.isNotApp)
      window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    if(!this.isNotApp)
      window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    userDataMethod(data){
      this.userData = data
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      if(this.width <= 960){
        this.mini = true
        this.$refs.AppBarComponent.changeMiniDeactivate()
      }else{
        this.$refs.AppBarComponent.changeMiniActivate()
      }
    }
  },
  computed: {
    getUserData(){
      return this.userData
    },
    isNotApp: function(){

      let names = [
        "login",
        "page404",
        "page500",
        "reset",
        "mfa",
        "verify",
        "verify_microsoft"
      ];

      return names.indexOf(this.$route.name) >= 0;
    },
    isMini: {
      get: function() {
        return this.mini == null ? localStorage.getItem("isMini") == "true" : this.mini;
      },
      set: function(val) {

        localStorage.setItem("isMini", val);

        this.mini = val;
      }
    }
  }
};
</script>
