<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="primary white--text">
                {{equipamento.Descricao}}
            </v-card-title>
            <v-card-text>
                <v-simple-table class="d-none d-lg-block" fixed-header style="zoom: 90%">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Tarefa
                                </th>
                                <th class="text-left">
                                    Data Próx. Manut.
                                </th>
                                <th class="text-left">
                                    Data Últ. Manut.
                                </th>
                                <th class="text-left">
                                    (HR) Contador Atual
                                </th>
                                <th class="text-left">
                                    (HR) Inter. Manut.
                                </th>
                                <th class="text-left">
                                    (HR) Próx. Manut.
                                </th>
                                <th class="text-left">
                                    (HR) Últi. Manut.
                                </th>
                                <th class="text-left">
                                    (KM) Contador Atual
                                </th>
                                <th class="text-left">
                                    (KM) Inter. Manut.
                                </th>
                                <th class="text-left">
                                    (KM) Últi. Manut.
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="preventiva in preventivas" :key="preventiva.id"
                            >
                                <td class="text-left">
                                    {{preventiva.DescricaoTarefa}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.Data_ProximaManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.Data_UltimaManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.HR_ContadorAtual}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.HR_IntervaloManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.HR_ProximaManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.HR_UltimaManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.KM_ContadorAtual}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.KM_IntervaloManut}}
                                </td>
                                <td class="text-left">
                                    {{preventiva.KM_UltimaManut}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-data-iterator
                    :items="preventivas"
                    item-key="id"
                    :items-per-page="-1"
                    hide-default-footer
                    class="d-block d-lg-none ma-0 pa-0"
                >
                    <template v-slot:default="{ items }">
                        <v-row class="ma-0 pa-0">
                            <v-col
                                class="ma-0 pa-0"
                                v-for="(item, index) in items"
                                :key="index+200000"
                                cols="12"
                            >
                                <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                    
                                    <v-card-title class="primary white--text">
                                        {{item.DescricaoTarefa}}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-list
                                            dense
                                        >
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="Próx. Manut." readonly :value="item.Data_ProximaManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="Últi. Manut." readonly :value="item.Data_UltimaManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(HR) Contador Atual" readonly :value="item.HR_ContadorAtual" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(HR) Intervalo Manut." readonly :value="item.HR_IntervaloManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(HR) Próxima Manut." readonly :value="item.HR_ProximaManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(HR) Última Manut." readonly :value="item.HR_UltimaManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(KM) Contador Atual" readonly :value="item.KM_ContadorAtual" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(KM) Intervalo Manut." readonly :value="item.KM_IntervaloManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                            
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field label="(KM) Última Manut." readonly :value="item.KM_UltimaManut" />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>

            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    name:"Preventiva",
    props:{
        equipamento: Object,
        preventivas: Array
    },
    data(){
        return{
            
        }
    }
}
</script>