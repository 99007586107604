import { render, staticRenderFns } from "./ConfirmModal.vue?vue&type=template&id=2deea02f&scoped=true&"
import script from "./ConfirmModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmModal.vue?vue&type=style&index=0&id=2deea02f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2deea02f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})
