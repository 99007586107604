<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">    
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-apps-box</v-icon> <span>Preventivas</span>
          </h1>
          
          <div style="position:relative">
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16"
              locale="pt-pt"
            >

              <template v-slot:item.actions="{item}">
                <IconView
                    v-if="item.hasPreventiva"
                    class="mr-2"
                    @click="viewPreventiva(item)"
                />
                
              </template>

            </v-data-table>
          </div>
        </v-col>
      </v-row>

    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import Preventivas from "@/api/Preventivas.js";
  import Equipamentos from "@/api/Equipamentos.js"
  import IconView from '@/components/UI/IconView.vue';
  
  export default {
    components: {
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchPreventivas()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "ODC - Preventivas";
    },
    data: () => ({      
      headers: [
        {
          text: 'Entity Key',
          sortable: true,
          value: 'EntityKey',
        },
        {
          text: 'Descrição',
          sortable: true,
          value: 'Descricao',
        },
        {
            text: "Opções",
            align: "center",
            value: "actions"
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      toDelete:{
        show: false,
        object: null
      },
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        hasPreventiva: true
      },
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "teams.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
    },
    methods:{      
      fillBaseData() {
  
        if(localStorage["preventivas-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["preventivas-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchPreventivas(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["preventivas-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Equipamentos.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetPreventivas()
      {
        this.filter = {
        };
  
        this.searchPreventivas();
      },
      viewPreventiva(item){
        this.$router.push('preventivas/' + item.Id)
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Preventivas',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  