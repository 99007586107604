import { render, staticRenderFns } from "./OTP.vue?vue&type=template&id=2d63dddc&scoped=true&"
import script from "./OTP.vue?vue&type=script&lang=js&"
export * from "./OTP.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d63dddc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VOtpInput})
